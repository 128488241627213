// utils/crypto.js
import CryptoJS from "crypto-js";

const SECRET_KEY = "huehuehuehuehuehue"; // Replace with a secure key

// Encrypt JSON data
export const encryptData = (data) => {
    try {
        const jsonString = JSON.stringify(data);
        const encrypted = CryptoJS.AES.encrypt(jsonString, SECRET_KEY).toString();
        return encrypted;
    } catch (error) {
        console.error("Error encrypting data:", error);
        return null;
    }
};

// Decrypt encrypted string
export const decryptData = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch (error) {
        console.error("Error decrypting data:", error);
        return null;
    }
};
