import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";

export default () => (
  <AnimationRevealPage>
    <Hero />
    {/* <MainFeature /> */}
    <Features />
    <MainFeature2 />
    {/* <Portfolio /> */}
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing customers are saying about our tech professionals. At ZFA Labs, our clients' satisfaction is our top priority. Here's what some of them have to say about working with us:"
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
          quote:
            "Our go-to partner for web development. Their innovative solutions and reliable service keep us ahead of the competition.",
          customerName: "Muskan Ansari",
          // customerTitle: ""
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          quote:
            "ZFA delivered an outstanding mobile app that exceeded our expectations. Their technical expertise is unmatched.",
          customerName: "Vedant Khandelwal",
          // customerTitle: "Founder of a med startup"
        }
      ]}
      textOnLeft={true}
    />


    {/* <ContactUsForm /> */}
    <SimpleFiveColumn />
  </AnimationRevealPage>
);
