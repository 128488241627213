import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ZFALandingPage from "demos/ZFALandingPage";
import CreateEntries from "demos/CreateEntry";
import VerifyCertificate from "demos/VerifyCertificate";
import AboutUs from "pages/AboutUs";
import VerticalWithAlternateImageAndText from "components/features/VerticalWithAlternateImageAndText";
import Services from "pages/Services";
import ContactUs from "pages/ContactUs";
import BlogIndex from "pages/BlogIndex";
import Careers from "pages/Careers";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/awlelelelelelelele" element={<CreateEntries />} />
          <Route path="/" element={<ZFALandingPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/blogs" element={<BlogIndex />} />

          <Route path="/careers" element={<Careers />} />
          <Route path="/verify/:id" element={<VerifyCertificate />} />
        </Routes>
      </Router>
    </>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
