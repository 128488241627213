import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import { Link } from "react-router-dom";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-0 max-w-screen-xl mx-auto`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">
        About
      </NavLink>
      <NavLink href="/services">
        Services
      </NavLink>

      {/* <NavLink href="#Testimonials">
        Testimonials
      </NavLink> */}
      {/* <NavLink href="/blogs">
        Blogs
      </NavLink> */}
      <NavLink href="/careers">
        Careers
      </NavLink>

    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/contact">
        Hire Us
      </PrimaryLink>
    </NavLinks>
  ];
  return (
    <AnimationRevealPage>
      <HeroContainer>
        <Header links={navLinks} />
        <MainFeature1
          subheading={<Subheading>About ZFA</Subheading>}
          heading="We are a modern tech startup."
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        />
        <MainFeature1
          subheading={<Subheading>Our Vision</Subheading>}
          heading="We aim to disrupt the development space."
          buttonRounded={false}
          primaryButtonText="Contact Us"
          imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
          textOnLeft={false}
          description="To empower businesses globally by delivering innovative digital solutions that transform ideas into impactful technologies, fostering growth, creativity, and inclusivity in the digital era."
        />
        <MainFeature1
          subheading={<Subheading>Our Mission</Subheading>}
          heading="Driving Excellence Through Innovation"
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          description="Our mission is to create cutting-edge applications and platforms that enhance user experiences and drive client success. We aim to blend creativity and technology, offering scalable and sustainable solutions tailored to individual client needs while maintaining a commitment to quality and innovation."
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        />
        <Features
          subheading={<Subheading>Our Values</Subheading>}
          heading="We follow these."
          description="We believe in innovation, collaboration, and delivering excellence to create impactful digital solutions that exceed client expectations."
          cards={[
            {
              imageSrc: SupportIconImage,
              title: "24/7 Support",
              description: "Our dedicated team is available round the clock to address your concerns and ensure uninterrupted service, empowering you to focus on growth."
            },
            {
              imageSrc: ShieldIconImage,
              title: "Strong Teams",
              description: "At ZFA Labs, we pride ourselves on our skilled, dynamic teams who work together to turn visions into reality and achieve outstanding results."
            },
            {
              imageSrc: CustomerLoveIconImage,
              title: "Customer Satisfaction",
              description: "We are committed to delivering tailored solutions and exceptional experiences that leave our clients delighted and their businesses thriving."
            },
          ]}
          linkText=""
        />
        {/* <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer /> */}
      </HeroContainer>
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
};
