import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-2 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const SectionHeading2 = tw.h2`text-2xl my-2 sm:text-5xl font-black tracking-wide text-center`
const Heading = tw(SectionHeading2)``;

const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-40 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const GridContainer = tw.div`grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "https://images.unsplash.com/photo-1667372393086-9d4001d51cf1?q=80",
      title: "Frontend Developer Intern",
      description: "Collaborate on building user-friendly, interactive web interfaces using technologies like React.js, HTML, CSS, and JavaScript. Gain hands-on experience with responsive design and modern web development practices.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://plus.unsplash.com/premium_photo-1675793715030-0584c8ec4a13?q=80",
      title: "Backend Developer Intern",
      description: "Dive into server-side programming, database management, and API development. Work with technologies like Node.js, Python, or Ruby to ensure seamless backend functionality and data integration.",
      locationText: "Remote, India",
    },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1573497019415-7c1e351d0bbf?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
    //   title: "Fullstack Developer Intern",
    //   description: "Work on both frontend and backend systems, integrating user-facing elements with server-side logic. Use tools like React.js, Node.js, and databases to build scalable and robust applications.",
    //   locationText: "Remote, India",
    // },
    {
      imageSrc: "https://plus.unsplash.com/premium_photo-1720287601300-cf423c3d6760?q=80",
      title: "UI/UX Designer Intern",
      description: "Work on designing intuitive and visually appealing interfaces. Collaborate with developers to enhance user experience and create wireframes and prototypes using tools like Figma or Adobe XD.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://plus.unsplash.com/premium_photo-1683121710572-7723bd2e235d?q=80",
      title: "Machine Learning Intern",
      description: "Experiment with AI models and algorithms to solve real-world problems. Learn to preprocess data, train models, and evaluate performance using tools like TensorFlow or PyTorch.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80",
      title: "Python Developer Intern",
      description: "Build scalable and efficient applications using Python. Work on projects involving web development, scripting, automation, and more, using frameworks like Flask or Django.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://images.pexels.com/photos/270623/pexels-photo-270623.png",
      title: "Java Developer Intern",
      description: "Develop backend applications and systems using Java. Work on APIs, frameworks like Spring, and object-oriented programming to build robust solutions.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80",
      title: "Data Science Intern",
      description: "Work on data preprocessing, visualization, and model building. Use Python, machine learning libraries, and statistical techniques to derive insights from complex datasets.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1542744095-fcf48d80b0fd?q=80",
      title: "HR Intern",
      description: "Assist with recruitment, onboarding, and employee engagement initiatives. Gain experience in human resources management and organizational development.",
      locationText: "Remote, India",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1552664730-d307ca884978?q=80",
      title: "Marketing & Sales Intern",
      description: "Develop and execute digital marketing strategies, including social media, email campaigns, and SEO. Learn to analyze performance metrics and optimize campaigns.",
      locationText: "Remote, India",
    },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1559526324-4b87b5e36e44?q=80",
    //   title: "Sales Intern",
    //   description: "Assist with lead generation, customer outreach, and sales strategy development. Gain hands-on experience in driving business growth and client relationships.",
    //   locationText: "Remote, India",
    // },
  ];

  const url = 'https://forms.gle/JHeSrV6DDoukAoGr5'

  const openForm = () => {
    window.open(url)
  }
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Internship Positions</Heading>
          {/* <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon /></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon /></NextButton>
          </Controls> */}
        </HeadingWithControl>
        {/* <CardSlider ref={setSliderRef} {...sliderSettings}> */}
        <GridContainer>{cards.map((card, index) => (
          <Card key={index}>
            <CardImage imageSrc={card.imageSrc} />
            <TextInfo>
              <TitleReviewContainer>
                <Title>{card.title}</Title>
                {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
              </TitleReviewContainer>
              <SecondaryInfoContainer>
                <IconWithText>
                  <IconContainer>
                    <LocationIcon />
                  </IconContainer>
                  <Text>{card.locationText}</Text>
                </IconWithText>
                {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
              </SecondaryInfoContainer>
              <Description>{card.description}</Description>
            </TextInfo>
            <PrimaryButton onClick={openForm}>Apply Now</PrimaryButton>
          </Card>
        ))}
        </GridContainer>
        {/* </CardSlider> */}
      </Content>
    </Container>
  );
};
