import React, { useEffect, useState } from "react";
import LogoImage from "./logo.png"
import CertIcon from "./certificateIcon.png";
import InvalidCert from "./invalid.png"
import "./verify.css"
import { decryptData, encryptData } from "./Encryption";
import { useParams } from "react-router-dom";
import { Blocks } from 'react-loader-spinner'

const baseURL = "https://api.jsonbin.io/v3/b/";
export default () => {
  const [internData, setInternData] = useState()
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  document.body.style.backgroundColor = "#36505b";
  useEffect(() => {
    if (id) {
      fetch(baseURL + id, {
        method: "GET",
        headers: {
          "X-Master-Key": "$2a$10$HzS/r2bmPKSEP2Bw/2EAbe8rm90n9.nHwJJ5h/Lnnxh1W314PHmTO",
          "X-Access-Key": "$2a$10$XqX/wj69i2vNZarAHfezx.czXaYjf1BLAtrNgTDmKOhNA.WRekzka"
        }
      }).then((res) => res.json()).then((res) => {
        if (res.record) {
          const data = decryptData(res.record.data)
          setInternData(data)
        } else {
          // alert(res.message)
        }
      }
      ).catch((err) => {
        console.error(err, "==============================");

      }).finally(() => {
        setLoading(false)
      })

    }
  }, [])
  return (
    // <AnimationRevealPage>
    <div className="container-verify" style={{
      background: "white", height: "calc(100vh - 32px)", maxHeight: "900px",
      minHeight: "500px", maxWidth: "600px",
      margin: "auto", borderRadius: 16, marginBlock: "16px", padding: 16,
    }}>
      <img src={LogoImage} width={200} style={{ display: "block", margin: "auto" }} />
      <h3 style={{
        textAlign: "center", fontSize: 26, marginBlock: 16, fontFamily: "Poppins", display: "flex", alignItems: "center",
        justifyContent: "center",
      }}>
        Certificate Verification!

      </h3>
      <hr></hr>
      {loading ? <div style={{
        display: "flex", justifyContent: "center", alignItems: "center", height: "90%"
      }}>
        <Blocks
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          visible={true}
        />
      </div> : <>

        {internData ? <div style={{
          // display: "flex", alignItems: "center"
        }}>

          <img src={CertIcon} width={100} style={{
            margin: "auto", marginBlock: 16
          }} />
          <h3 style={{
            textAlign: "center", fontSize: 18, marginBlock: 16, fontFamily: "Poppins", fontWeight: 600, color: "#36505b"
          }}>
            This certificate is valid and was issued to {internData?.name}
          </h3>
          <hr></hr>
          <h3 style={{
            textAlign: "center",
            fontSize: 18, marginTop: 16, fontFamily: "Poppins", fontWeight: 500,
            color: "#36505b"
          }}>
            Designation
          </h3>
          <h3 style={{
            textAlign: "center",
            fontSize: 18, marginBlock: 8, fontFamily: "Poppins", fontWeight: 600,
            color: "rgb(98 83 245)"
          }}>
            {internData.designation}
          </h3>

          <h3 style={{
            textAlign: "center",
            fontSize: 18, marginTop: 16, fontFamily: "Poppins", fontWeight: 500,
            color: "#36505b"
          }}>
            Issued Date
          </h3>
          <h3 style={{
            textAlign: "center",
            fontSize: 18, marginBlock: 8, fontFamily: "Poppins", fontWeight: 600,
            color: "rgb(98 83 245)"
          }}>
            {internData.issued_on}
          </h3>

          <h3 style={{
            textAlign: "center",
            fontSize: 18, marginTop: 16, fontFamily: "Poppins", fontWeight: 500,
            color: "#36505b"
          }}>
            Internship Period
          </h3>
          <h3 style={{
            textAlign: "center",
            fontSize: 18, marginBlock: 8, fontFamily: "Poppins", fontWeight: 600,
            color: "rgb(98 83 245)"
          }}>
            {internData.duration}
          </h3>

        </div>
          : <div>
            <img src={InvalidCert} width={100} style={{
              margin: "auto", marginBlock: 16
            }} />
            <h3 style={{
              textAlign: "center", fontSize: 18, marginBlock: 16, fontFamily: "Poppins", fontWeight: 600, color: "#36505b"
            }}>
              Sorry! This certificate is invalid!
            </h3>

            <h3 style={{
              textAlign: "center",
              fontSize: 18, marginTop: 36, fontFamily: "Poppins", fontWeight: 500,
              color: "#36505b", border: "1px solid red", borderRadius: 8, paddingBlock: 16, paddingInline: 8
            }}>
              If you believe this is an error, please contact our support team at interns@zfatech.in!
            </h3>
          </div>}
      </>}
    </div>
    // </AnimationRevealPage>
  )
};
