import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import { Link } from "react-router-dom";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import ThreeColSlider from "components/cards/ThreeColSlider";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-0 max-w-screen-xl mx-auto`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">
        About
      </NavLink>
      <NavLink href="/services">
        Services
      </NavLink>

      {/* <NavLink href="#Testimonials">
        Testimonials
      </NavLink> */}
      {/* <NavLink href="/blogs">
        Blogs
      </NavLink> */}
      <NavLink href="/careers#">
        Careers
      </NavLink>

    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/contact">
        Hire Us
      </PrimaryLink>
    </NavLinks>
  ];
  return (
    <AnimationRevealPage>
      <HeroContainer>
        <Header links={navLinks} />
        <ThreeColSlider />
        {/* <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer /> */}
      </HeroContainer>
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
};
