import React, { useEffect, useState } from "react";
import LogoImage from "./logo.png"
import CertIcon from "./certificateIcon.png";
import InvalidCert from "./invalid.png"
import "./verify.css"
import { decryptData, encryptData } from "./Encryption";
import { useParams } from "react-router-dom";
import { Blocks } from 'react-loader-spinner'

const baseURL = "https://api.jsonbin.io/v3/b/";
export default () => {
    const [internData, setInternData] = useState();
    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [duration, setDuration] = useState("");
    const [password, setPassword] = useState("");
    const [issued_on, setIssued_on] = useState("");
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    document.body.style.backgroundColor = "#36505b";

    const validateFields = (data) => {
        const errors = {};

        // Validate Name
        if (!data.name || data.name.trim() === "") {
            errors.name = "Name is required.";
        } else if (data.name.length > 50) {
            errors.name = "Name cannot exceed 50 characters.";
        }

        // Validate Issued On
        if (!data.issued_on) {
            errors.issued_on = "Issued date is required.";
        }

        // Validate Designation
        if (!data.designation || data.designation.trim() === "") {
            errors.designation = "Designation is required.";
        } else if (data.designation.length > 50) {
            errors.designation = "Designation cannot exceed 50 characters.";
        }

        // Validate Duration
        if (!data.duration || data.duration.trim() === "") {
            errors.duration = "Duration is required.";
        } else if (!/^\d+\s(Months|Month|Week|Weeks)$/.test(data.duration)) {
            errors.duration = "Duration must be in the format 'X Months' or 'X Month'.";
        }

        return errors;
    };

    const createEntry = () => {
        const data = {
            name, duration, designation, issued_on
        }
        const validationErrors = validateFields(data);

        if (Object.keys(validationErrors).length > 0) {
            return alert("Fill the form properly!!!")
        }
        if (
            Object.keys(validationErrors).length === 0
        ) {
            if (password === "huehuehue") {
                setLoading(true)
                const encryptedData = encryptData(data)
                fetch(baseURL, {
                    method: "POST",
                    headers: {
                        "X-Master-Key": "$2a$10$HzS/r2bmPKSEP2Bw/2EAbe8rm90n9.nHwJJ5h/Lnnxh1W314PHmTO",
                        "X-Access-Key": "$2a$10$XqX/wj69i2vNZarAHfezx.czXaYjf1BLAtrNgTDmKOhNA.WRekzka",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: encryptedData
                    })
                }).then((res) => res.json()).then((res) => {
                    if (res.metadata) {
                        setInternData(res.metadata.id)
                    } else {
                        setInternData(res.message)
                    }
                }
                ).catch((err) => {
                    console.error(err, "==============================");

                }).finally(() => {
                    setLoading(false)
                })
            } else {
                alert("INTRUDERRRRRR!!!")

            }

        }
    }
    return (
        // <AnimationRevealPage>
        <div className="container-verify" style={{
            background: "white", height: "calc(100vh - 32px)", maxHeight: "900px",
            minHeight: "800px", maxWidth: "600px",
            margin: "auto", borderRadius: 16, marginBlock: "16px", padding: 16,
        }}>
            <img src={LogoImage} width={200} style={{ display: "block", margin: "auto" }} />
            <h3 style={{
                textAlign: "center", fontSize: 26, marginBlock: 16, fontFamily: "Poppins", display: "flex", alignItems: "center",
                justifyContent: "center",
            }}>
                Certificate Entry!

            </h3>
            <hr></hr>
            {loading ? <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", height: "90%"
            }}>
                <Blocks
                    height="100"
                    width="100"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
            </div> : <>
                {!internData ? <div style={{
                    // display: "flex", alignItems: "center"
                }}>

                    <h3 style={{
                        textAlign: "center", fontSize: 18, marginBlock: 16, fontFamily: "Poppins", fontWeight: 600, color: "#36505b"
                    }}>
                        Fill the form
                    </h3>
                    <hr></hr>

                    <input style={{
                        maxWidth: "400px",
                        width: "100%", borderRadius: 6,
                        display: "block", marginBlock: 8, marginInline: "auto", border: "1px solid blue", padding: 8
                    }}
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <input style={{
                        maxWidth: "400px",
                        width: "100%", borderRadius: 6,
                        display: "block", marginBlock: 8, marginInline: "auto", border: "1px solid blue", padding: 8
                    }}
                        placeholder="Designation"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                    />

                    <input style={{
                        maxWidth: "400px",
                        width: "100%", borderRadius: 6,
                        display: "block", marginBlock: 8, marginInline: "auto", border: "1px solid blue", padding: 8
                    }}
                        placeholder="Issued Date"
                        value={issued_on}
                        onChange={(e) => setIssued_on(e.target.value)}
                    />

                    <input style={{
                        maxWidth: "400px",
                        width: "100%", borderRadius: 6,
                        display: "block", marginBlock: 8, marginInline: "auto", border: "1px solid blue", padding: 8
                    }}
                        placeholder="Internship Period"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                    />
                    <hr />
                    <input style={{
                        maxWidth: "400px",
                        width: "100%", borderRadius: 6,
                        display: "block", marginBlock: 8, marginInline: "auto", border: "1px solid blue", padding: 8
                    }}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button style={{
                        maxWidth: "400px",
                        borderRadius: 6, width: "100%", fontFamily: "Poppins", outline: "none",
                        display: "block", padding: 16, background: "blue", color: "white", marginInline: "auto", marginBlock: 16
                    }} onClick={createEntry}>Submit</button>
                </div> : <div>
                    <h3 style={{
                        textAlign: "center",
                        fontSize: 18, marginBlock: 8, fontFamily: "Poppins", fontWeight: 600,
                        color: "rgb(98 83 245)"
                    }}>
                        {
                            internData.length === 24 ? " You did it!! here's your id : " : "shit dude, something failed : "
                        }
                        {internData}
                    </h3>
                    {internData.length === 24 && <a style={{
                        textAlign: "center", fontFamily: "Poppins", fontSize: 18, color: "blue", display: "block", fontStyle: "italic", textDecoration: "underlined"
                    }} href={"https://zfatech.in/verify/" + internData} >Click here to Open Verification Page</a>}
                </div>
                }

            </>}

        </div >
        // </AnimationRevealPage>
    )
};
