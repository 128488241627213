import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-80 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`

const Heading = tw(SectionHeading)`w-full`;

export default () => {
  const cards = [
    {
      imageSrc:
        require("../../images/appdev.jpg"),
      subtitle: "Paid",
      title: "Mobile App Development",
      description:
        "Stay ahead in the mobile-first world with our comprehensive mobile app development services. From concept to launch, we create user-friendly, high-performance apps for both iOS and Android platforms.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        require("../../images/webdev.jpg"),
      subtitle: "Paid",
      title: "Website Development",
      description:
        "Elevate your online presence with our professional website development services. We craft responsive, SEO-friendly websites that not only look great but also perform excellently.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        require("../../images/uiux.jpg"),
      subtitle: "Paid",
      title: "UI/UX Design",
      description:
        "Create a stunning online presence with our expert web design services. Our designs are not only visually appealing but also user-centric and optimized for performance.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        require("../../images/testing.jpg"),
      subtitle: "Paid",
      title: "Testing Automation",
      description:
        "Ensure the highest quality for your products with our comprehensive QA testing services. We provide rigorous testing to identify and fix issues before they impact your users.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        require("../../images/cloud.jpg"),
      subtitle: "Paid",
      title: "Cloud Services",
      description:
        "Secure and scalable cloud storage solutions tailored to your business needs. Access your data anytime, anywhere, with our reliable cloud services.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        require("../../images/appdev.jpg"),
      subtitle: "Paid",
      title: "Digital Marketing",
      description:
        "Boost your online presence and reach your target audience with our digital marketing services. We create and implement effective marketing strategies to grow your business.",
      url: "https://timerse.com"
    },


  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading>
          {/* <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription> */}
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      {/* <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 /> */}
    </Container>
  );
};
